<template>
    <div class="category-wrap">
        <div v-for="(item, index) in categoryData" class="category-item" :class="index % 5 === 4 && 'last'" @click="onItemClick(item)">
            <img :src="item.icon" />
            <div class="title">{{ item.name }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Category',
    created() {
        this.loadCategoryData();
    },
    data() {
        return {
            categoryData: [],
        }
    },
    methods: {
        onItemClick(item) {
            window.location.href = `./category.html?cate=${item.name}`;
        },
        loadCategoryData() {
            fetch('/resource/game_list.json')
                .then(response => response.json())
                .then(data => {
                    this.categoryData = data;
                })
        }
    },
}
</script>

<style lang="less" scoped>
@import '@/assets/styles/variable.less';

.category-wrap {
    padding: 30px;
    border-radius: 30px;
    background-color: @main-color-lighter;
    display: flex;
    flex-wrap: wrap;
    .category-item {
        width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        margin-bottom: 30px;
        img {
            width: 80px;
            height: 80px;
            border-radius: 30px;
            margin-bottom: 15px;
        }
        .title {
            font-size: 30px;
            font-weight: 600;
            color: @main-color;
            text-align: center;
        }
    }
    .last {
        margin-right: 0;
    }
}
</style>
