<template>
    <div class="banner-wrapper">
        <van-swipe :autoplay="2000">
            <van-swipe-item v-for="item in bannerData" :key="item.id" class="banner-item" @click="onBannerClick(item)">
                <img :src="item.pic" />
                <div class="title">{{ item.title }}</div>
            </van-swipe-item>
        </van-swipe>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    created() {
        this.getBannerData();
    },
    data() {
        return {
            bannerData: [],
        }
    },
    methods: {
        onBannerClick(item) {
            window.location.href = `./detail.html?id=${item.jump_to}`;
        },
        getBannerData() {
            fetch('/resource/banner.json')
                .then(response => response.json())
                .then(data => {
                    this.bannerData = data;
                })
        },
    },
}
</script>

<style lang="less" scoped>
@import '@/assets/styles/variable.less';

.banner-wrapper {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    background-color: @main-color-lighter;
    border-radius: 30px;
    .banner-item {
        width: 300px;
        img {
            width: 100%;
            border-radius: 20px;
            margin-bottom: 20px;
        }
        .title {
            font-size: 36px;
            font-weight: 600;
            color: @main-color;
            text-align: center;
        }
    }
}
</style>