<template>
    <div class="module-wrap">
        <div v-if="title" class="title">{{ title }}</div>
        <div class="game-box">
            <div v-for="(item, index) in data" class="game-item" :class="index % 3 === 1 && 'center'" @click="onItemClick(item)">
                <img :src="item.icon" />
                <div class="title clamp">{{ item.title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Module',
    props: ['title', 'data'],
    methods: {
        onItemClick(item) {
            window.location.href = `./detail.html?id=${item.id}`;
        },
    },
}
</script>

<style lang="less" scoped>
@import '@/assets/styles/variable.less';

.module-wrap {
    padding: 30px;
    background-color: @main-color-lighter;
    border-radius: 30px;
    .title {
        font-size: 40px;
        color: @main-color;
        font-weight: 600;
        margin-bottom: 30px;
    }
    .game-box {
        display: flex;
        flex-wrap: wrap;
        .game-item {
            width: 190px;
            cursor: pointer;
            img {
                width: 190px;
                height: 190px;
                border-radius: 20px;
            }
            .title {
                font-size: 36px;
                font-weight: 600;
                color: @main-color;
                text-align: center;
                -webkit-line-clamp: 1;
            }
        }
        .center {
            margin: 0 30px;
        }
    }
}
</style>