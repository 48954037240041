<template>
    <div class="footer-wrap">
        <img class="logo" src="@/assets/images/logo.png" />
        <div class="link-box">
            <div class="link-item" @click="onLinkClick('user-agreement')">User Agreement</div>
            <div class="link-item" @click="onLinkClick('privacy-policy')">Privacy Policy</div>
            <div class="link-item" @click="onLinkClick('contact-us')">Contact Us</div>
        </div>
        <div class="copyright">
            Copyright © 2023.All rights Reserved.
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    methods: {
        onLinkClick(path) {
            window.location.href = `./${path}.html`;
        },
    },
}
</script>

<style lang="less" scoped>
@import '@/assets/styles/variable.less';

.footer-wrap {
    background-color: @main-color;
    padding: 30px 60px;
    .logo {
        width: 300px;
        margin-bottom: 30px;
    }
    .link-box {
        display: flex;
        justify-content: space-between;
        .link-item {
            padding: 30px 0;
            border-bottom: 1px solid #efefef;
            color: #fff;
            font-size: 26px;
            font-weight: 600;
            cursor: pointer;
        }
    }
    .copyright {
        margin: 40px 0;
        font-size: 36px;
        color: #fff;
        text-align: center;
        font-weight: 600;
    }
}
</style>