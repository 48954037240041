<template>
    <div class="header-wrap">
        <i v-if="!showMenu" class="iconfont icon-menu" @click="onMenuToggle"></i>
        <i v-if="showMenu" class="iconfont icon-close" @click="onMenuToggle"></i>
        <img class="logo" src="@/assets/images/logo.png" />
        <i class="iconfont icon-Search" @click="onSearchToggle"></i>
        <div v-if="showMenu" class="menubar-box">
            <div class="menubar" :class="showMenu && 'slide'">
                <div class="menu-item" @click="onHomeClick">
                    Home
                    <i class="iconfont icon-more"></i>
                </div>
                <div v-for="(item, index) in menuData" @click="onMenuClick(item)" class="menu-item" :class="index === menuData.length - 1 ? 'last' : ''">
                    {{ item.name }}
                    <i class="iconfont icon-more"></i>
                </div>
            </div>
        </div>
        <div v-if="showSearch" class="search-box">
            <div class="input-box">
                <div class="input-wrap">
                    <i class="iconfont icon-Search"></i>
                    <input v-model="searchKey" placeholder="Search" @input="onInputChange" />
                </div>
                <span class="cancel-btn" @click="onSearchToggle">Cancel</span>
            </div>
            <div v-if="searchResult.length > 0" class="result-box">
                <div v-for="item in searchResult" class="result-item" @click="onItemClick(item)">
                    <span class="text">{{ item.title.split(searchKey)[0] }}</span>
                    <span class="search-text">{{ searchKey }}</span>
                    <span class="text">{{ item.title.split(searchKey)[1] }}</span>
                </div>
                <div class="result-more" @click="onSearchMoreClick">See More</div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'Header',
    created() {
        this.loadGameList();
        this.loadGameData();
    },
    data() {
        return {
            showMenu: false,
            menuData: [],
            showSearch: false,
            searchKey: '',
            gameData: [],
            searchResult: [],
        }
    },
    methods: {
        onMenuToggle() {
            this.showMenu = !this.showMenu;
            if (this.showMenu) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        },
        onHomeClick() {
            window.location.href = './index.html';
        },
        onMenuClick(item) {
            window.location.href = `./category.html?cate=${item.name}`;
        },
        onInputChange: _.debounce(function() {
            this.searchResult = this.gameData.filter(item => item.title.includes(this.searchKey));
        }, 500),
        onItemClick(item) {
            window.location.href = `./detail.html?id=${item.id}`;
        },
        onSearchMoreClick() {
            window.location.href = `./search.html?s=${this.searchKey}`;
        },
        onSearchToggle() {
            this.showSearch = !this.showSearch;
            if (this.showSearch) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
                this.searchKey = '';
                this.searchResult = [];
            }
        },
        loadGameList() {
            fetch('/resource/game_list.json')
                .then(response => response.json())
                .then(data => {
                    this.menuData = data;
                })
        },
        loadGameData() {
            fetch('/resource/games.json')
                .then(response => response.json())
                .then(data => {
                    this.gameData = Object.keys(data).map(key => data[key]);
                })
        },
    },
}
</script>

<style lang="less" scoped>
@import '@/assets/styles/variable.less';

.header-wrap {
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    background-color: @main-color;
    position: relative;
    .icon-menu, .icon-Search, .icon-close {
        font-size: 50px;
        color: #eee;
        cursor: pointer;
    }
    .logo {
        width: 200px;
    }

    .menubar-box {
        width: 750px;
        height: calc(100vh - 120px);
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 120px;left: 0;
        z-index: 99;
        overflow-y: auto;
        .menubar {
            width: 600px;
            background-color: @main-color;
            padding: 30px;
            border-top-right-radius: 30px;
            transform: translateX(-600px);
        }
        .slide {
            transform: translate(0);
            transition: all 800ms;
        }
        .menu-item {
            padding: 50px 40px;
            font-size: 40px;
            color: #eee;
            cursor: pointer;
            border-bottom: 1px solid #efefef;
            display: flex;
            justify-content: space-between;
            img {
                width: 40px;
                height: 40px;
            }
        }
        .last {
            border: none;
        }
    }

    .search-box {
        width: 750px;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;left: 0;
        z-index: 99;
        .input-box {
            height: 120px;
            padding: 0 30px;
            background-color: @main-color;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .input-wrap {
                width: 550px;
                position: relative;
                input {
                    width: 100%;
                    height: 80px;
                    background-color: @main-color-darken;
                    font-size: 40px;
                    padding-left: 80px;
                    border: none;
                    outline: none;
                    border-radius: 20px;
                    color: #eee;
                    &::placeholder {
                        font-size: 40px;
                        color: #aaa;
                    }
                }
                .iconfont {
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    top: 20px;left: 20px;
                    color: #eee;
                    font-size: 40px;
                }
            }
            .cancel-btn {
                color: #eee;
                font-size: 40px;
                cursor: pointer;
            }
        }
        .result-box {
            width: 690px;
            height: 700px;
            margin: 0 30px;
            background-color: #fff;
            border-radius: 20px;
            padding: 30px;
            overflow-y: auto;
            .result-item {
                font-size: 36px;
                color: #000;
                margin-bottom: 50px;
                cursor: pointer;
                .search-text {
                    color: #ff6629;
                }
            }
            .result-more {
                font-size: 40px;
                color: #000;
                padding-top: 20px;
                border-top: 1px solid #eee;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}
</style>
