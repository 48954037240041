import Vue from 'vue'
import App from './App.vue'
import { Swipe, SwipeItem } from 'vant';

/** 自适应布局脚本 */
import "@/plugins/flexble";

/** 样式文件 */
import 'vant/lib/index.css';
import "@/assets/styles/index.less";

Vue.use(Swipe);
Vue.use(SwipeItem);

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#app')
