<template>
    <div id="app">
        <Header></Header>
        <div class="app-box">
            <Banner></Banner>
            <div class="split"></div>
            <Category></Category>
            <div class="split"></div>
            <Module :data="gameData"></Module>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Banner from '@/components/Banner.vue';
import Category from '@/components/Category.vue';
import Module from '@/components/Module.vue';
import Footer from '@/components/Footer.vue';
export default {
    name: 'App',
    components: {
        Header, Banner, Category, Module, Footer,
    },
    created() {
        this.loadGameData();
    },
    data() {
        return {
            gameData: [],
        }
    },
    methods: {
        loadGameData() {
            fetch('/resource/games.json')
                .then(response => response.json())
                .then(data => {
                    this.gameData = Object.keys(data).map(key => data[key]).sort(() => Math.random() - 0.5 > 0 ? 1 : -1).slice(0, 99);
                })
        },
    },
}
</script>

<style lang="less">
@import '@/assets/styles/variable.less';

#app {
    width: 750px;
    min-height: 100vh;
    margin: 0 auto;
    background-color: @background-color;
    .app-box {
        padding: 30px;
        .split {
            height: 30px;
        }
    }
}
</style>
